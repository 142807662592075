(($) => {
	const debounceTime = 500;

	const debounce = (func, wait) => {
		let timeout;
		return function executedFunction(...args) {
			const later = () => {
				clearTimeout(timeout);
				func(...args);
			};
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		};
	};

	const headlinePlacement = (sectionClass, helperClass) => {
		$(`${sectionClass}`).each((index, ele) => {
			const div = $(ele).find(`${helperClass}`),
				divContent = $(ele).find(div).clone(),
				tab = $(ele).find(
					`.bs-tab-slider .bs-slider-tabs .slick-track`
				);
			if (!tab.find('.bs-div--content').length) {
				divContent.prependTo(tab);
			}
		});
	};

	const appendTitles = debounce(() => {
		if ($(window).width() > 991) {
			headlinePlacement('.r-tabslr-l4--frm1', '.bs-div--content');
		} else {
			$('.r-tabslr-l4--frm1 .bs-slider-tabs .slick-track')
				.find('.bs-div--content')
				.remove();
		}
	}, debounceTime);

	appendTitles();

	$(window).on('resize', () => {
		appendTitles();
	});
})(jQuery); /*eslint-disable-line  no-undef */
