$(() => {
  $(".bs-tab-slider--leadership-team").on("focusin", function(){
    fancyBoxJquery(".bs-post.leadership [data-fancybox]").fancybox({
      beforeShow: function() {
        $('body').addClass('leadership-team-page');
      },
      afterClose: function() {
        $('body').removeClass('leadership-team-page');
      },
    });
  });
})
