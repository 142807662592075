(($) => {
	$(() => {
		$('.r-ctacol-l4--frm2').each((index, element) => {
			const svgPath = `<svg class="svg">
      <clipPath id="clipPath" clipPathUnits="objectBoundingBox">
      <path d="M0.953,0.713 L0.667,0.667 L0.713,0.953 C0.648,0.983,0.576,1,0.5,1 C0.224,1,0,0.776,0,0.5 C0,0.424,0.017,0.352,0.047,0.287 L0.333,0.333 L0.287,0.047 C0.352,0.017,0.424,0,0.5,0 C0.776,0,1,0.224,1,0.5 C1,0.576,0.983,0.648,0.953,0.713"></path></clipPath>
    </svg>`;
			$(element).find('picture').after(svgPath);
		});
	});
})(jQuery); /*eslint-disable-line  no-undef */
