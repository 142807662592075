$(() => {
    if (typeof FWP != "undefined" && FWP.settings.pagination.pager_type != "undefined") {
        $(document).on('facetwp-loaded', () => {
            if (FWP.settings.pagination.pager_type == "numbers") {
                facetScrollToDiv();
            }
        })

        function facetScrollToDiv() {
            $('html, body').animate({
                scrollTop: $('.facetwp-template').offset().top - 200
            }, 500);
        }

        $(document).on('click', '.facetwp-type-pager .facetwp-page', () => {
            $(document).on('facetwp-loaded', () => {
                facetScrollToDiv();
            })
        });
    }
});