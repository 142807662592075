(($) => {
	const videoSlider = $('.bs-slider--r-hrobnr-l2-frm1');

	// On slide change, pause all videos
	videoSlider.on('beforeChange', () => {
		$('.bs-slider--r-hrobnr-l2-frm1 .slick-slide video').each(
			(index, element) => {
				$(element).get(0).controls = 'false';
				$(element).get(0).pause();
				$(element).get(0).currentTime = 0;
			}
		);
	});

	// On slide chnage, play a video inside the current slide
	videoSlider.on('afterChange', () => {
		if (videoSlider.find('.slick-slide.slick-current video').length !== 0) {
			$('.bs-slider--r-hrobnr-l2-frm1 .slick-current video')[0].play();
		}
	});
})(jQuery); /*eslint-disable-line  no-undef */
