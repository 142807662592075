$(() => {
	const $body = $('body');
	const $megaSearch = $('.mega-search');
	const header = $('header');

	// add "on-scrolled" class to body on page scroll
	const scrollTop = () => {
		const scroll = $(window).scrollTop();
		if (scroll >= 10) {
			$body.addClass('on-scrolled');
		} else {
			$body.removeClass('on-scrolled');
		}
		if ($('#wpadminbar').length > 0) {
			if (scroll >= 10) {
				$body.addClass('has-admin-bar');
			} else {
				$body.removeClass('has-admin-bar');
			}
		}
	};

	// loading functions on scroll, load
	$(window).on('scroll load', scrollTop);

	// header search popup input wrapper class adding
	const search = $('.mega-search-wrap');
	const innerWrapper = search.find('.mega-search');
	innerWrapper.each((index, element) => {
		$(element).find('input').wrapAll('<div class="input-wrapper" />');
	});

	//header search open close
	$('header .mega-search-wrap .mega-search').append(
		'<span class="search-helper"></span>'
	);

	$('header .search-helper').on('click', (e) => {
		$(e.currentTarget).find().parent().toggleClass('active');
		$(e.currentTarget).parent().parent().toggleClass('active');
		if ($($megaSearch).hasClass('mega-search-closed')) {
			$($megaSearch).addClass('mega-search-open');
			$($megaSearch).removeClass('mega-search-closed');
		} else {
			$($megaSearch).addClass('mega-search-closed');
			$($megaSearch).removeClass(' mega-search-open');
		}
		$('.input-wrapper > input').focus();
	});

	const hideSearchbar = () => {
		$('.mega-search-wrap form')
			.removeClass('mega-search-open')
			.addClass('mega-search-closed');
	};

	header
		.find('form.mega-search')
		.append('<span class="search-close"></span>');

	header.mouseleave(() => {
		$($body).removeClass('normal-header');
	});
	header.mouseover(() => {
		$($body).addClass('normal-header');
	});

	$(document).on('click', '.search-close', () => {
		hideSearchbar();
	});
});
