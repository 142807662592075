const resource = $(".bs-single-post--single-resource, .bs-section--global-health-post-block .bs-post");
let cloneElement;
resource.each((e, i) => {
	let categoryColor = $(i).find('.bs-post__category').data('bullet');
	$(i).find('.bs-post__category').append('<div class="bullet-icon"></div>');
	$(i)
		.find('.bs-post__category > .bullet-icon')
		.css('background-color', categoryColor);
	cloneElement = $(i).find('.bs-post__date').clone();
	if (cloneElement) {
		$(i).find('.bs-post__category').append(cloneElement);
		$(i).find('.bs-post__category').siblings('.bs-post__date').remove();
	}
});