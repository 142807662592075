const teamMemberContent = $('.bs-post__target--popup-leadership');

const getProfileWrapperNode = (memberContent) => {
  let profileWrapper = memberContent.find('.bs-post__profile-wrapper')
  if (profileWrapper.length === 0) {
    profileWrapper = $('<div class="bs-post__profile-wrapper"></div>')
    memberContent.find('.bs-post').prepend(profileWrapper)
    return profileWrapper
  } else {
    return $(profileWrapper[0])
  }
}

teamMemberContent.each((i, element) => {
  let profileImage = $(element).find('.bs-post__image')
  let profileName = $(element).find('.bs-post__title')
  let profileDesignation = $(element).find('.bs-post-meta_e25_position')
  let profileSocialLink = $(element).find('.bs-post__details > a')
  const profileContainer = getProfileWrapperNode($(element));
  $(profileContainer).append(profileImage, profileName, profileDesignation, profileSocialLink)
});