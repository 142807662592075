//ADD SLIDER SELECTOR WITH ',' TO ADD SLIDE COUNT
const slider = $(
	'.r-tesslr-l2--frm1 .slick-slider, .bs-section--contact-us-location-slider .slick-slider, .r-blbslr-l2--frm1 .slick-slider, .r-tesslr-l1--frm1 .slick-slider, .bs-slider--r-blbslr-l3-frm1, .bs-section--attitude-tracker-post-slider .slick-slider'
);

slider.each((i, element) => {
	$(element).on('init afterChange', function (e, slick) {
		const counter = getSliderCounterNode($(this), slick);
		counter.text(`${slick.currentSlide + 1} / ${slick.slideCount}`);
		$(this).append(counter);
	});
});

const getSliderCounterNode = (slider, sl) => {
	let sliderCounter = slider.find('.slick-count');
	let sliderDots = slider.find('.slick-dots');
	let sliderArrows = slider.find('.slick-arrow');
	if (
		sliderCounter.length === 0 &&
		sl.slideCount > 1 &&
		sliderDots.length != 1
	) {
		sliderCounter = $('<div class="slick-count"></div>');
		slider.append(sliderCounter);
		return sliderCounter;
	} else {
		return $(sliderCounter[0]);
	}
};

// logo slider sliders count make as counter
const logoSlider = $('.r-logslr-l1--imp2 .bs-logo-slider__inner');
if (logoSlider.length != 0) {
	logoSlider.each((i, elem) => {
		$(elem).on(
			'init afterChange',
			function (event, slick, currentSlide, nextSlide) {
				if (!slick.$dots) {
					return;
				}
				let totalSlides = slick.$dots[0].children.length; // get number of total sliders
				let perPage = JSON.parse($(elem).attr('data-slick'))
					.responsive[0].settings.slidesToShow; // slide to show count
				let currentItemNumber =
					(currentSlide > 0 ? currentSlide / perPage : 0) + 1; // get current slide number
				if (totalSlides !== 1) {
					const appendNumbers = getCounterElement(elem);
					appendNumbers.text(`${currentItemNumber} / ${totalSlides}`);
				}
			}
		);
	});
}
// get slider counter element parsing slider element
const getCounterElement = (slideElem) => {
	let sliderCounter = $(slideElem).find('.slick-count');
	if (sliderCounter.length == 0) {
		sliderCounter = $('<div class="slick-count"></div>');
		slideElem.append(sliderCounter[0]);
		return sliderCounter;
	} else {
		return $(sliderCounter[0]);
	}
};
