$(() => {
    //mobile pagination
    const mobilePaginationClassAdding = () => {
        if ( $( '.facetwp-facet-pagination' ).length > 0 ) {
          if ( $( window ).width() < 576 ) {
            const paginationElements = $(
              '.facetwp-facet-pagination .facetwp-page'
            );
            const dotsContent = '<a class="facetwp-page dots">…</a>';
            const activeIndex = $( '.facetwp-facet-pagination' )
              .find( '.active' )
              .index();
            const lastIndex = paginationElements.length - 1;
            paginationElements.each( ( i, e ) => {
              const element = $( e );
              if (
                ! (
                  i === 0 ||
                  i === 1 ||
                  i === lastIndex - 1 ||
                  i === lastIndex ||
                  i === activeIndex - 1 ||
                  i === activeIndex ||
                  i === activeIndex + 1
                )
              )
                element.addClass( 'hide-mobile' );
              if ( i > 1 && i < activeIndex - 1 ) element.addClass( 'left-items' );
              if ( i > activeIndex + 1 && i < lastIndex - 1 )
                element.addClass( 'right-items' );
            } );
            const leftHiddenContent = $(
              '.facetwp-facet-pagination .facetwp-page.hide-mobile.left-items'
            );
            const rightHiddenContent = $(
              '.facetwp-facet-pagination .facetwp-page.hide-mobile.right-items'
            );
            if (
              typeof leftHiddenContent !== 'undefined' &&
              leftHiddenContent.length > 0
            ) {
              leftHiddenContent.first().before( dotsContent );
              leftHiddenContent.remove();
            }
            if (
              typeof rightHiddenContent !== 'undefined' &&
              rightHiddenContent.length > 0
            ) {
              rightHiddenContent.last().after( dotsContent );
              rightHiddenContent.remove();
            }
          }
        }
    };

    //active 'All' checkbox when reset button click
    $('.reset-filters').on('click', function () {
        $('.facet-checkbox-select-all').addClass('checked');
    });

    //on page load hide the reset button
    if ($('.facetwp-selection-value').length <= 0) {
        $('.reset-selection').hide();
    }

    //handle 'All' check box functionality when click on sub checkboxes
    $(document).on('click', '.facetwp-type-checkboxes .facetwp-checkbox:not(.disabled)', () => {
        const facet = $(event.target).parent().data('name');
        const allCheckboxElement = $(`.facetwp-all-${facet}`);
        if (facet) {
            let isChecked = !$(event.target).hasClass('checked');
            if (isChecked) {
                if (!$(event.target).siblings().hasClass('checked')) {
                    allCheckboxElement.addClass('checked');
                }
            } else {
                if (allCheckboxElement.hasClass('checked')) {
                    allCheckboxElement.removeClass('checked');
                }
            }
        }
    });

    //'All' check box on click action
    $(document).on('click', '.facet-checkbox-select-all', () => {
        $(event.target).addClass('checked')
    });

    //hide reset button when facet items are not selected
    $(document).on('facetwp-loaded', () => {
        mobilePaginationClassAdding();
        let queryString = FWP.buildQueryString();
        if ('' === queryString) { // no facets are selected
            $('.reset-selection').hide();
        } else {
            $('.reset-selection').show();
        }
    });

    if (typeof FWP != "undefined") {
        const facets = FWP.facets;
        //on page reload, when facet filter items applied, uncheck the relavent facet 'All' checkbox
        $.each(facets, (key, facet) => {
            if (facets[key].length) {
                $(`.facetwp-all-${key}`).removeClass('checked');
            }
        });
    }

    //mobile pagination
    $( window ).on( 'load resize', function ( e ) {
        mobilePaginationClassAdding();
    } );
});