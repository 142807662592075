$(() => {
  const setFacetData = () => {
    $('.bs-section--resources-posts .bs-post').each(function () {
      const postDate = $(this).find('.bs-post__date');
      const postCategory = $(this).find('.bs-post__category');
      if (postCategory.length > 0 && postDate.length > 0) {
        $($(this).find('.bs-post__category')).append(postDate);
      }
    });

    const sidebarFilter = $('.bs-div--sidebar-filter');
    $('.bs-div--sidebar-filter .bs-pro-button').off('click').on('click', function (e) {
      e.preventDefault();
      sidebarFilter.toggleClass('expand');
    });
  }
  setFacetData();
  $(document).on('facetwp-loaded', () => {
    setFacetData();
  });

  const cloneSideBarForm = $('.bs-section--resources-posts .bs-div--sidebar-form').clone();
  $('.bs-section--resources-posts > .container').append(cloneSideBarForm);
});