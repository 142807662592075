$(() => {
	const jobsParent = $('#resumator-jobs');
	let actionUrl = window.location.origin + '/career-details/';

	const createFormForParsValues = () => {
		let formHtml;
		formHtml = `
      <form action="${actionUrl}" target="_blank" method="post" id="jobDataSubmit">
        <input type="hidden" name="vacancy_url" id="vacancy_url" value="">
        <button type="submit">submit</button>
      </form>
    `;
		jobsParent.append(formHtml);
	};

	createFormForParsValues();
	const jobsList = $('#resumator-jobs .resumator-job');
	if (jobsList.length != 0) {
		jobsList.each((i, ele) => {
			const viewBtn = $(ele).find('a.resumator-job-link'); // view btn element for get site link
			const jobTitle = $(ele).find('.resumator-job-title').text(); // vacancy title text
			if (viewBtn.length != 0) {
				viewBtn.on('click auxclick', (e) => {
					e.preventDefault();
					const jobLink = e.target.href;
					$('#vacancy_url').val(jobLink); // iframe url
					$('#jobDataSubmit').attr(
						'action',
						actionUrl + '?view=' + string_to_slug(jobTitle)
					); // add job title to url display purpose
					$('#jobDataSubmit').submit(); // submit hidden form
				});
			}
		});
	}

	const string_to_slug = (str) => {
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		str = str.toLowerCase();
		// remove accents, swap ñ for n, etc
		let from = 'àáäâèéëêìíïîòóöôùúüûñçěščřžýúůďťň·/_,:;';
		let to = 'aaaaeeeeiiiioooouuuuncescrzyuudtn------';
		for (let i = 0, l = from.length; i < l; i++) {
			str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}
		str = str
			.replace('.', '-')
			.replace(/[^a-z0-9 -]/g, '')
			.replace(/\s+/g, '-')
			.replace(/-+/g, '-')
			.replace(/\//g, '');
		return str;
	};
});
