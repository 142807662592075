$(() => {
	const $careerSection = $('.bs-section--careers-open-position .resumator-job');

	$careerSection.each(function () {
		$('p').each(function () {
			var $this = $(this);
			if ($this.html().replace(/\s|&nbsp;/g, '').length == 0)
				$this.remove();
		});
		$(this).append('<span class="drop-down-helper"></span>');
	});

	let dropDownHelper = $('.bs-section--careers-open-position .resumator-job > span.drop-down-helper');
	dropDownHelper.on('click', function () {
		let $this = $(this);
		$this
			.parents('.bs-section--careers-open-position .resumator-job')
			.siblings('div.active')
			.removeClass('active')
			.find('.resumator-job-description')
			.slideUp(600);
		$this.prev('.resumator-job-description').slideToggle(200);
		$this.parent('div').toggleClass('active');
	});

	$('.resumator-job-info .resumator-job-department ')
  .each(function(index, el) {
    var textNode = $(el.nextSibling);
    if (textNode[0].nodeType == Node.TEXT_NODE) {
      let departmentWrap = $(el).wrap('<span class="resumator-job-department-wrapper"></span>').closest('.resumator-job-department-wrapper');
      textNode.appendTo(departmentWrap);
    }
  });
});